<template>
    <footer class="app-footer py-3 --border-top text-muted">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <span
                            >&copy; {{ new Date().getFullYear() }} {{$root?.partner?.labels?.['global.companyLegalName'] || ''}}</span>
                        <span class="px-1">&middot;</span>
                        <a :href="$root?.partner?.resources?.terms || ''" @click.prevent="">Terms</a>
                        <span class="px-1">&middot;</span>
                        <a href="#" @click.prevent="" v-show="$root.isUserLogged">Logout</a>
                        <router-link href="#"  to="/login" v-show="!token" class="pointer-events-none text-decoration-none text-muted">Login</router-link>
                        <a href="#" @click.prevent="logout()" v-show="token">Logout</a> 
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>


export default {
    name: 'AppFooter',

    computed: {
        token() {
            return this.$store.getters.token;
        }
    }, 

    methods: {
        logout() {
            this.$root.logoutIntent();
        }
    }

}

</script>

<style scoped>
.app-footer {
    background-color:var(--bg-canvas);
    font-size: 15px;
}
</style>