
import defaultOpportunity from './DefaultOpportunity.js';
import defaultCompany from './DefaultCompany.js';
import wp from '@/services/WordpressService.js';
import { EventBus } from './EventBus.js';

export default {

    async signOn(context, creds) {
        context.commit('SET_SIGNON_ERROR', '');

        /**
         * @param {Object} user
         * @param {object} user.ID
         * @param {Array}  user.message If signOn failed
         * @param {String} user.user_email
         * @param {String} user.user_login
         * @param {String} user.display_name
         * @param {String} user.user_nicename
         * @param {String} user.user_registered
         * @param {String} user.token
         * 
         */

        context.commit('SET_LOADING_STATE', true);
        let user = await wp.wp_signon(creds);
        if (user && user.ID) {
            context.commit('SET_USER', user);
            context.commit('SET_TOKEN', user?.token);

        } else if (user && user.message) {
            let message = user.message.join('');
            context.commit('SET_SIGNON_ERROR', message);
        }
        context.commit('SET_LOADING_STATE', false);
    },



    async signOut({ commit }) {
        commit('SET_USER', null);
        commit('SET_STATE', {});
        await wp.wp_signout();
        // document.location.reload();
    },




    async loginAs({ commit }, email) {
        let user = await wp.loginAs(email);

        if (user && user.ID) {
            commit('SET_USER', user);
            commit('SET_TOKEN', user?.token);
            wp.token(user?.token);

            setTimeout(() => {
                document.location.reload();
            }, 500);

        } else if (user && user.message) {
            // unlikely to run
            let message = user.message.join('');
            commit('SET_SIGNON_ERROR', message);

        } else {
            alert('ERROR: unable to login as "' + email + '"');
        }
    },



    async getAccessGroup({ commit }) {
        commit('SET_LOADING_STATE', true);
        let group = await wp.getAccessGroup();
        commit('SET_LOADING_STATE', false);

        return group
    },


    /**
     * 
     *
     *
     *
     */
    async createOpportunity({ commit }, data) {

        console.log('>>> createOpportunity')
        let opportunity;
        data.ID = 0;

        commit('SET_LOADING_STATE', true);
        opportunity = { ...defaultOpportunity, ...data };
        opportunity.post_content = opportunity.content;
        delete opportunity.content;
        let response = await wp.createOpportunity(opportunity);
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: for some reason this does not dispatch. Find out. In the meantime
         * the refresh action must be invoked from the creating script.
         */
        await this.dispatch('fetchOpportunities');

        EventBus.trigger(EventBus.events.OPPORTUNITY_CREATED, [response]);

        return response;
    },



    async fetchOpportunities({ commit, state }) {
        let collection;

        if (state.opportunities.length > 0) {
            collection = state.opportunities;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getOpportunities();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_OPPORTUNITIES', collection);

        return collection;
    },



    async fetchOpportunity({ commit, state }, name) {
        let opportunity;

        if (typeof state.active.opportunities[name] != 'undefined') {
            opportunity = state.active.opportunities[name];
        } else {
            commit('SET_LOADING_STATE', true);
            opportunity = await wp.getOpportunity(name);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_OPPORTUNITY', {
            name: name,
            data: opportunity
        });

        commit('SET_CURRENT_OPPORTUNITY', opportunity);

        return opportunity;
    },


    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     *
     * @param {Object} request
     * @param {Object} request.form Name of the form
     * @param {Object} request.field The field to be updated
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     *
     */
    async updateOpportunityFormField({ commit, state, getters }, request) {
        if (getters?.currentOpportunity?.content?.[request.form]?.fields) {
            state.current.opportunity.content[request.form].fields[request.field] = request.value;
        }

        commit('SET_LOADING_STATE', true);
        await wp.updateOpportunityFormField(request);
        commit('SET_LOADING_STATE', false);
    },



    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.form Name of the form
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     *
     */
    async updateOpportunityForm({ commit, state }, request) {
        commit, state;
        commit('SET_LOADING_STATE', true);
        await wp.updateOpportunityForm(request);
        commit('SET_LOADING_STATE', false);
    },




    /**
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Object} context.state
     * @param {Object} request
     * @param {Object} request.ID Name of the form
     * @param {Object} request.post_title
     * @param {Object} request.post_excerpt
     *
     *
     */
    async updateOpportunity({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.updateOpportunity(request);
        commit('SET_LOADING_STATE', false);


        commit('SET_OPPORTUNITY', {
            name: response.name,
            data: response
        });


        EventBus.trigger(EventBus.events.OPPORTUNITY_UPDATED, [response]);

        return response;
    },

    /**
     * 
     * @param {*} context 
     * @param {*} context.state 
     * @param {*} context.commit
     * 
     * @param {*} request 
     * @param {*} request.ID
     * @param {*} request.name
     * @param {*} request.title
     */
    async deleteOpportunity({ state, commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.deleteOpportunity(request.ID, false /** forceDelete */);
        commit('SET_LOADING_STATE', false);

        /** remove if strategy is current */
        (state.current.opportunity?.name == request.name) && (state.current.opportunity = null);

        /** remove if strategy is in active list */
        (state.active.opportunities[request.name]) && delete (state.active.opportunities[request.name]);

        EventBus.trigger(EventBus.events.OPPORTUNITY_DELETED, [response]);
        return true;
    },







    /**
     * 
     * @param {*} context 
     * @param {*} context.state 
     * @param {*} context.commit
     * 
     * @param {*} request 
     * @param {*} request.ID
     * @param {*} request.name
     * @param {*} request.title
     */
    async trashOpportunity({ state, commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.trashOpportunity(request.name);
        commit('SET_LOADING_STATE', false);

        /** remove if strategy is current */
        (state.current.opportunity.name == request.name) && (state.current.opportunity = null);

        /** remove if strategy is in active list */
        (state.active.opportunities[request.name]) && delete (state.active.opportunities[request.name]);

        EventBus.trigger(EventBus.events.OPPORTUNITY_TRASHED, [response]);
        return true;
    },







    /**
     * @param {Function} commit
     * @param {Object} request
     * @param {Object} request.ID 
     * @param {Object} request.post_title
     *
     *
     */
    async renameOpportunity({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.renameOpportunity(request);
        commit('SET_LOADING_STATE', false);

        EventBus.trigger(EventBus.events.OPPORTUNITY_RENAMED, [response]);
    },





    async duplicateOpportunity({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.duplicateOpportunity(request);
        commit('SET_LOADING_STATE', false);

        EventBus.trigger(EventBus.events.OPPORTUNITY_DUPLICATED, [response]);
    },





    async createOpportunityTemplate({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.createOpportunityTemplate(request);
        commit('SET_LOADING_STATE', false);

        EventBus.trigger(EventBus.events.OPPORTUNITY_TEMPLATE_CREATED, [response]);
    },




    async fetchOpportunityTemplates({ commit, state }) {
        let collection;

        if (state.opportunityTemplates.length > 0) {
            collection = state.opportunityTemplates;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getOpportunityTemplatesFromCache();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_OPPORTUNITY_TEMPLATES', collection);

        return state.opportunityTemplates;
    },






    /**
     * Create a new company
     *
     *
     *
     */
    async createCompany({ commit }, data) {
        let entry;
        data.ID = 0;
        commit('SET_LOADING_STATE', true);
        entry = { ...defaultCompany, ...data };
        entry.post_content = entry.content;
        delete entry.content;
        let response = await wp.createCompany(entry);
        commit('SET_LOADING_STATE', false);

        EventBus.trigger(EventBus.events.COMPANY_CREATED, [response]);
        return response;
    },


    async fetchCompanies({ commit, state }) {
        let collection;

        if (state.companies.length > 0) {
            collection = state.companies;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getCompanies();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_COMPANIES', collection);
        return state.companies;
    },



    async fetchCompany({ commit, state }, name) {
        let item;

        if (typeof state.active.companies[name] != 'undefined') {
            item = state.active.companies[name];
        } else {
            commit('SET_LOADING_STATE', true);
            item = await wp.getCompany(name);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_COMPANY', {
            name: name,
            data: item
        });

        commit('SET_CURRENT_COMPANY', item);

        return state.active.companies[name];
    },



    async getUserCompany({ commit, state, getters }) {

        // console.log('state.current.company', state.current.company);
        // debugger;

        let item;
        let name = getters.user?.user_login;

        if (state.current.company != null) {
            item = state.current.company;

        } else {
            commit('SET_LOADING_STATE', true);
            item = await wp.getUserCompany();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_COMPANY', {
            name: name,
            data: item
        });

        commit('SET_CURRENT_COMPANY', item);

        return state.current.company;
    },




    async updateUserCompany({ commit, state }) {

        let request = {
            ID: state.current.company.ID,
            post_name: state.current.company.name,
            post_title: state.current.company.title,
            post_content: state.current.company.content,
        };

        commit('SET_LOADING_STATE', true);
        let response = await wp.updateUserCompany(request);
        commit('SET_LOADING_STATE', false);

        EventBus.trigger(EventBus.events.USER_COMPANY_UPDATED, [response]);

        return response;
    },

    /**
     *
     * @param {Object} context
     * @param {Object} context.state
     *
     * @param {Object} request
     * @param {Object} request.form Name of the form (expected to be "company")
     * @param {Object} request.field The field to be updated
     * @param {Object} request.value
     * @param {Object} request.opportunity_id
     *
     */
    async updateUserCompanyFormField({ state }, request) {
        if (state.current?.company?.content?.company?.fields) {
            state.current.company.content[request.form].fields[request.field] = request.value;
        }

        await this.dispatch('updateUserCompany');

        return state.current.company;
    },











    async fetchStrategies({ commit, state }) {
        let collection;

        if (state.strategies.length > 0) {
            collection = state.strategies;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getStrategiesData();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_STRATEGIES', collection);

        return state.strategies;
    },




    async reloadStrategies({ commit, state }) {
        commit('SET_STRATEGIES', []);
        await this.dispatch('fetchStrategies');

        return state.strategies;
    },


    async fetchStrategy({ commit, state }, name) {
        let item;

        if (typeof state.active.strategies[name] != 'undefined') {
            item = state.active.strategies[name];
        } else {
            commit('SET_LOADING_STATE', true);
            item = await wp.getStrategyData(name);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_STRATEGY', {
            name: name,
            data: item
        });

        return state.active.strategies[name];
    },


    /**
     * IMPORTANT: 
     * 
     * The stratgy title also includes the "kind", therefore this method must 
     * preprocess the title field before submitting to the server. When a 
     * strategy is requested, the server will also process the tilte to 
     * generate the "kind" value.
     * 
     * e.g. Strategy real title: "Cold Emails: My Strategy". The corresponding
     * in memory value is "My Strategy".
     */
    async updateCurrentStrategy({ state, commit }) {
        let strategy = state.current?.strategy;
        let post_title = strategy?.kind.split('-').map((part) => {
            part = part[0].toUpperCase() + part.substr(1);
            return part;
        }).join(' ') + ': ' + strategy.title;

        let request = {
            ID: strategy.ID,
            post_content: strategy.content,
            post_title: post_title,
            post_excerpt: strategy.excerpt,
        };


        commit('SET_LOADING_STATE', true);
        /**
         * IMPORTANT: the return data does not nee to be set in the store
         * since the data was already modified before making the request 
         * to the server.
         */
        await wp.updateStrategy(request);

        commit('SET_LOADING_STATE', false);

        state.strategies && (state.strategies = state.strategies?.map((item) => {
            if (item.ID == state.current.strategy.ID) {
                item = state.current.strategy;
            }
            return item;
        }));

        return state.current?.strategy;
    },



    /**
     * TODO: test this method, it may not work
     * @param {*} param0 
     * @param {*} name 
     * @returns 
     */
    async trashStrategy({ state, commit }, name) {
        commit('SET_LOADING_STATE', true);
        await wp.trashStrategy(name);
        /** reload strategies */
        await this.dispatch('reloadStrategies');
        commit('SET_LOADING_STATE', false);

        /** remove if strategy is current */
        (state.current.strategy?.name == name) && (state.current.strategy = null);

        /** remove if strategy is in active list */
        (state.active.strategies[name]) && delete (state.active.strategies[name]);

        /** remove if strategy is in strategies collection */
        state.strategies && (state.strategies = state.strategies?.filter((item) => {
            return item.name !== name;
        }));

        return true;
    },




    async deleteStrategy({ state, commit }, strategy) {
        commit('SET_LOADING_STATE', true);
        await wp.deleteStrategy(strategy.ID);
        /** reload strategies */
        await this.dispatch('reloadStrategies');
        commit('SET_LOADING_STATE', false);

        /** remove if strategy is current */
        (state.current.strategy?.name == strategy.name) && (state.current.strategy = null);

        /** remove if strategy is in active list */
        (state.active.strategies[strategy.name]) && delete (state.active.strategies[strategy.name]);

        /** remove if strategy is in strategies collection */
        state.strategies && (state.strategies = state.strategies?.filter((item) => {
            return item.name !== strategy.name;
        }));

        return true;
    },





    /**
     * 
     * @param {*} context 
     * @param {*} context.commit
     * 
     * @param {*} opportunityId
     */
    async deleteStrategiesByOpportunityId({ commit }, opportunityId) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.deleteStrategiesByOpportunityId(opportunityId);
        /** reload strategies */
        await this.dispatch('reloadStrategies');
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: get back the list of strategies deleted so they can be removed from the store.
         */
        return response;

    },





    /**
     * 
     * @param {*} context 
     * @param {*} context.commit
     * 
     * @param {*} opportunityId
     */
    async unlinkStrategiesFromOpportunityId({ commit }, opportunityId) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.unlinkStrategiesFromOpportunityId(opportunityId);
        /** reload strategies */
        await this.dispatch('reloadStrategies');
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: get back the list of strategies deleted so they can be removed from the store.
         */
        return response;

    },



    /**
     * 
     * @param {*} context 
     * @param {*} context.commit
     * 
     * @param {*} request
     * @param {*} request.ID The ID of the strategy to be duplicated
     * @param {*} request.post_title 
     * @param {*} request.post_excerpt 
     * @param {*} request.meta 
     * @param {*} request.meta.opportunity_id 
     * @param {*} request.meta.opportunity_name 
     * @param {*} request.meta.opportunity_title 
     * 
     */
    async duplicateStrategy({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.duplicateStrategy(request);
        await this.dispatch('reloadStrategies');
        commit('SET_LOADING_STATE', false);

        /**
         * TODO: get back the list of strategies deleted so they can be removed from the store.
         */
        return response;

    },


    /**
     * 
     * @param {*} context.commit 
     * @param {*} request 
     * @param {*} request.ID The ID of the strategy you wish to use as a reference for the new template
     * @param {*} request.post_title The title for the new template (must include a siffix if applicable).
     */
    async createTemplateFromStrategy({ commit }, request) {
        commit('SET_LOADING_STATE', true);
        let response = await wp.createTemplateFromStrategy(request);
        commit('SET_LOADING_STATE', false);

        return response;
    },






















    async generateSWOT({ commit, state }, states) {
        state.reports.swot = null;
        commit('SET_LOADING_STATE', true);
        let report = await wp.generateSWOT(states);
        commit('SET_LOADING_STATE', false);
        commit('SET_SWOT_REPORT', report);

        return state.reports.swot;
    },




    async getIndicators({ commit, state }) {
        let collection;

        if (typeof state.reports.indicators != 'undefined') {
            collection = state.reports.indicators;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getIndicators();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_SWOT_INDICATORS', collection);

        return state.reports.indicators;
    },




    async fetchTableOfContents({ commit, state }, options) {
        let collection;

        if (state.tableOfContents?.length) {
            collection = state.tableOfContents;
        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getTableOfContents(options);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_TABLE_OF_COTENTS', collection);

        return state.tableOfContents;

    },





    async fetchStrategyTemplatesKind({ commit, state }, kind) {
        let collection;

        if (state.strategyTemplates?.[kind]?.length) {
            collection = state.strategyTemplates[kind];

        } else {
            commit('SET_LOADING_STATE', true);
            collection = await wp.getStrategyTemplatesKind(kind);
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_STRATEGY_TEMPLATES_KIND', { kind: kind, collection: collection });

        return state.strategyTemplates[kind];
    },



    setCurrentOpportunity({ commit, state }, data) {
        commit, state;
        commit('SET_CURRENT_OPPORTUNITY', data);
    },

    setCurrentForm({ commit, state }, data) {
        commit, state;
        commit('SET_CURRENT_FORM', data);
    },


    /**
     * @param {Objetc} data
     *
     * @return {String} base64 scrambled string
     *
     */
    objectToAppc(context, data) {
        context;
        let json = JSON.stringify(data, null, 4);
        let appc = btoa(json)
            /* create chunks of 36 characters */
            .match(/.{0,36}/g)
            /* loop thruogh chunks (lines) */
            .map((line) => {
                /* create chunks of 36 characters */
                line = line.match(/.{0,6}/g).join(' ').split('').reverse().join('');
                return line;
            })
            /* back to string */
            .join('\n');
        return appc;
    },

    /**
     * @param {String} appc base64 scrambled string
     *
     * @return {Object}
     *
     */
    appcToObject(context, appc) {
        context;
        appc = appc.split('\n').map((line) => {
            return line.split('').reverse().join('');
        }).join('');

        let json = atob(appc.replace(/\s/g, ''));
        let object = JSON.parse(json);

        return object;
    },










    /* Admin / Users */


    async fetchUsers({ commit, state }) {
        let users;

        if (state.users?.length > 0) {
            users = state.users;

        } else {
            commit('SET_LOADING_STATE', true);
            users = await wp.getUsers();
            commit('SET_LOADING_STATE', false);
        }

        commit('SET_USERS', users);

        return users;
    },


    /* Partners (for whitelabel implementation) */

    getPartner({ commit, state }) {
        let store = this;
        // Acquire the domain name at runtime
        const hostname = /^localhost/.test(document.location.hostname) && localStorage.getItem('partner')? 
            localStorage.getItem('partner') : window.location.hostname;

        // Return a Promise that resolves with the configuration data when it is loaded
        return new Promise((resolve, reject) => {
            reject
            if (state?.partner?.name) {
                resolve(state.partner);
            } else {
                commit('SET_LOADING_STATE', true);
                

                (async function(){
                    let partner = await store.getters.wp.run('getPartnerConfiguration', [{hostname: hostname}]);
                    state.partner = partner;
                    resolve(state.partner);
                    commit('SET_LOADING_STATE', false);
                })();

                // let configurationURL = '/appc/getPartnerConfiguration';

                // `/resources/partners/${domainName}/configuration.json`
                // fetch(configurationURL)
                //     .then(response => response.json())
                //     .then(data => {
                //         commit('SET_LOADING_STATE', false);
                //         commit('SET_PARTNER', data);
                //         resolve(state.partner);
                //     })
                //     .catch(error => {
                //         commit('SET_LOADING_STATE', false);
                //         reject(`Error loading configuration data for domain "${domainName}": ${error}`);
                //     });
            }
        });
    }, 


    updatePartner({ commit, state }) {
        let store = this;
        // Acquire the domain name at runtime
        const hostname = /^localhost/.test(document.location.hostname) && localStorage.getItem('partner')? 
            localStorage.getItem('partner') : window.location.hostname;

        // Return a Promise that resolves with the configuration data when it is loaded
        return new Promise((resolve, reject) => {
            reject
            if (state?.partner?.name) {
                resolve(state.partner);
            } else {
                commit('SET_LOADING_STATE', true);
                

                (async function(){
                    let partner = await store.getters.wp.run('getPartnerConfiguration', [{hostname: hostname}]);
                    state.partner = partner;
                    resolve(state.partner);
                    commit('SET_LOADING_STATE', false);
                })();

                // let configurationURL = '/appc/getPartnerConfiguration';

                // `/resources/partners/${domainName}/configuration.json`
                // fetch(configurationURL)
                //     .then(response => response.json())
                //     .then(data => {
                //         commit('SET_LOADING_STATE', false);
                //         commit('SET_PARTNER', data);
                //         resolve(state.partner);
                //     })
                //     .catch(error => {
                //         commit('SET_LOADING_STATE', false);
                //         reject(`Error loading configuration data for domain "${domainName}": ${error}`);
                //     });
            }
        });
    }



}