<template>
  <div class="dashboard d-flex flex-column vh-100">
    <HeaderMain></HeaderMain>
    <WelcomeBanner class="flex-fill"></WelcomeBanner>
    <AppFooter></AppFooter> 
  </div>
</template>

<script>

import WelcomeBanner from "./WelcomeBanner.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "Dashboard",
  components: {
    WelcomeBanner,
    AppFooter,
  },
};
</script>
