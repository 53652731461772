import { createStore } from 'vuex';
// import axios from 'axios';
import wp from '@/services/WordpressService.js';
import defaultTabs from './DefaultTabs.js';
import { EventBus } from './EventBus.js';


import LogRocket from 'logrocket';

import actionsCommon from './actions.common.js';
import actionsApp from './actions.app.js';
import actionsWorkbook from './actions.workbook.js';
import actionsForm from './actions.form.js';
import actionsEntry from './actions.ai.js';
import actionsAI from './actions.entry.js';



// axios
// const axios = require('axios');

export default createStore({
	state: {
		version: '0.1.0',

		loading: false,

		global: {
			PUSH_DELAY: 1000,
			DEFAULT_VIEW: '',
			LOGIN_VIEW: 'login'
		},

		current: {
			app: null, 
			workbook: null, 
			form: null, 
			entry: null, 
			opportunity: null, 
			strategy: null, 
			company: null, 
			token: null,
			tokens: {},
			/**
			 * Used in the Page strategy to set the size of the viewport. Valid values are: desktop, mobile
			 */
			viewport: 'desktop', 

			/**
			 * use in the StrategyList component to filter strategies bt opportunity or by kind
			 */
            kindFilter: '',
            opportunityFilter: '', 
		}, 

		/**
		* @param {Object} user
		* @param {object} user.ID
		* @param {Array}  user.message If signOn failed
		* @param {String} user.user_email
		* @param {String} user.user_login
		* @param {String} user.display_name
		* @param {String} user.user_nicename
		* @param {String} user.user_registered
		* @param {String} user.token
		*/
		user: {},

		partner: {},

		apps: [],
		workbooks: [],
		forms: [],
		entries: [],
		opportunities: [],
		opportunityTemplates: [],
		companies: [],
		strategies: [],
		strategyTemplates: {
			/**
			 * ads: [...],
			 * cold-emails: [...],
			 * landing-pages: [...],
			 * ...
			*/
		},
		reports: {},
		tableOfContents: [],

		active: {
			apps: {},
			workbooks: {},
			forms: {},
			entries: {},
			opportunities: {},
			companies: {},
			strategies: {}
		}, 


		views: {
			apps: {
			}
		},

		/**
		 * TODO: consider removing
		 */
		tabs: defaultTabs,

		errors: {
			signOn: ''
		}
	},

	/**
	 *	Mutations
	 *	
	 *	
	 *
	 */
	mutations: {
		/** Reset state */
		SET_STATE(state, status) {
			state = status;
		}, 


		SET_USER(state, status) {
			state.user = status;

			if(!status) {
				localStorage.removeItem('user');
			} else {
				localStorage.setItem('user', JSON.stringify(status));
			}
		},

		SET_TOKEN(state, status) {
			state.current.token = status;
		},

		SET_SIGNON_ERROR(state, status) {
			state.errors.signOn = status;
		},



		// VIEWS

		SET_VIEW_DATA(state, status) {
			let name = status.name;
			let data = status.data;

			// set in active list
			state.views[name] = data;
		},





		/** APPS */
		SET_APPS(state, status) {
			state.apps = status;
		},

		SET_APP(state, status) {
			let name = status.name;
			let data = status.data;

			// set in active list
			state.active.apps[name] = data;
			
			// set in list
			state.apps?.length > 0 && state.apps.map((item, i)=>{
				if(item.name == name) {
					state.apps[i] = data;
				}
				return item;
			});
		},

		SET_CURRENT_APP(state, status) {
			state.current.app = status;
		},





		/** WORKBOOKS */
		SET_WORKBOOKS(state, status) {
			state.workbooks = status;
		},

		SET_WORKBOOK(state, status) {
			let name = status.name;
			let data = status.data;

			// set in active list
			state.active.workbooks[name] = data;
			
			// set in list
			state.workbooks.length > 0 && state.workbooks.map((item, i)=>{
				if(item.name == name) {
					state.workbooks[i] = data;
				}
				return item;
			});
		},

		SET_CURRENT_WORKBOOK(state, status) {
			state.current.workbook = status;
		},




		/** FORMS */
		SET_FORMS(state, status) {
			state.forms = status;
		},

		/**
		 * 
		 * @param {*} state 
		 * @param {*} status.name 
		 * @param {*} status.data 
		 */
		SET_FORM(state, status) {
			let name = status.name;
			let data = status.data;

			// set in active list
			state.active.forms[name] = data;
			
			// set current
			state.current.form = data;

			
			// set in list
			state.forms.length > 0 && state.forms.map((item, i)=>{
				if(item.name == name) {
					state.forms[i] = data;
				}
				return item;
			});
		},

		SET_CURRENT_FORM(state, status) {
			state.current.form = status;
		},




		/** ENTRIES */
		SET_ENTRIES(state, status) {
			state.entries = status;
		},

		/**
		 * 
		 * @param {*} state 
		 * @param {*} status.name 
		 * @param {*} status.data 
		 */
		SET_ENTRY(state, status) {
			let name = status.name;
			let data = status.data;

			// set in active list
			state.active.entries[name] = data;
			
			// set current
			state.current.entry = data;

			
			// set in list
			state.entries.length > 0 && state.entries.map((item, i)=>{
				if(item.name == name) {
					state.entries[i] = data;
				}
				return item;
			});
		},



		/** OPPORTUNITIES */
		SET_OPPORTUNITIES(state, status) {
			state.opportunities = status;
		},

		SET_OPPORTUNITY(state, status) {
			let name = status.name;
			let data = status.data;

			// set in active list
			state.active.opportunities[name] = data;
			
			// set in list
			state.opportunities.length > 0 && state.opportunities.map((item, i)=>{
				if(item.name == name) {
					state.opportunities[i] = data;
				}
				return item;
			});
		},

		SET_CURRENT_OPPORTUNITY(state, status) {
			state.current.opportunity = status;
			state.current.opportunityFilter = status.name;
		},



		/** OPPORTUNITY TEMPLATES */
		SET_OPPORTUNITY_TEMPLATES(state, status) {
			state.opportunityTemplates = status;
		},



		/** STRATEGY TEMPLATES */
		SET_STRATEGY_TEMPLATES_KIND(state, data) {
			let kind = data.kind;
			let collection = data.collection;
			state.strategyTemplates[kind] = collection;
		},



		/** REPORTS */
		SET_SWOT_REPORT(state, status) {
			state.reports.swot = status;
		},

		SET_SWOT_INDICATORS(state, status) {
			state.reports.indicators = status;
		},



		/** COMPANIES */

		SET_COMPANIES(state, status) {
			state.companies = status;
		},

		SET_COMPANY(state, status) {
			let name = status.name;
			let data = status.data;
			state.active.companies[name] = data;
		},

		SET_CURRENT_COMPANY(state, status) {
			state.current.company = status;
		},





		SET_TABLE_OF_COTENTS(state, status) {
			state.tableOfContents = status;
		},



		/**
		 * TODO: consider removing this mutation. Tabs may not be needed.
		 * 
		 * @param {Object} state
		 * @param {Object} data
		 * @param {String} data.form The name of the form
		 * @param {String, Boolean} data.completed
		 *
		 *
		 */
		SET_FORM_COMPLETION(state, data) {
			state.tabs.forEach((group)=>{
				group.forms.forEach((form)=>{
					let formName = data.form;
					let completed = data.completed == true || data.completed == 'true'? true : false;

					if(form.name == formName) {
						form.completed = completed
					}
				});
			});
		},

		// SET_CURRENT_FORM(state, status) {
		// 	state.current.form = status;
		// },







		SET_STRATEGIES(state, status) {
			state.strategies = status;
		},

		SET_STRATEGY(state, status) {
			let name = status.name;
			let data = status.data;
			state.active.strategies[name] = data;
			state.current.strategy = data;
		},

		SET_LOADING_STATE(state, status) {
			state.loading = status;
		},


		SET_USERS(state, status) {
			state.users = status;
		},

		SET_PARTNER(state, status) {
			state.partner = status;
		}

	},



	/**
	 *	Actions
	 *	
	 *	
	 *
	 */
	actions: {
		...actionsCommon, 
		...actionsApp,
		...actionsWorkbook, 
		...actionsForm,
		...actionsEntry,
		...actionsAI
	},

  

	/**
	 *	Getters
	 *	
	 *	
	 *
	 */
	getters: {




		workbooks(state /*, getters */) {
			return state.workbooks;
		},





		opportunities(state /*, getters */) {
			return state.opportunities;
		},


		opportunityTemplates(state) {
			return state.opportunityTemplates;
		},





		strategies(state /*, getters */) {
			return state.strategies;
		}, 

		currentStrategy(state /*, getters */) {
			return state.current.strategy;
		}, 




		loading(state) {
			return state.loading;
		}, 

		currentOpportunity(state) {
			return state.current.opportunity;
		}, 

		tabs(state) {
			return state.tabs;
		}, 

		currentForm(state) {
			return state.current.form;
		}, 


		company(state) {
			return state.current.company;
		}, 


		tableOfContents(state) {
			return state.tableOfContents;
		}, 

		eventBus() {
			return EventBus;
		},

		wp() {
			return wp;
		}, 

		user(state) {
			let user;
			if(state.user?.ID) {
				return state.user
			} else {
				user = localStorage?.user? JSON.parse(localStorage.user) : null;
				state.user = user;
			}

			if(localStorage?.user && !/localhost/.test(document.location.hostname)) {

				LogRocket.identify(user.user_email);

				LogRocket.init('1ldktm/coreoffer', {
					network: {
						requestSanitizer: request => {
							if (request.url.includes('wp_signon')) {
								request.body = null;
							}
							return request;
						},
					},
				});
			}

			return state.user;
		}, 

		/**
		 * This refers to the authentication token if it has not yet expired, otherwise
		 * it will return null
		 * 	
		 * @param {*} state 
		 * @returns {String} Return the to
		 */
		token(state) {
			let token = null;

			if(state.current?.token) {
				return state.current.token;

			} else if( localStorage.getItem('appcropolis.expose.token') ) {
				token = localStorage.getItem('appcropolis.expose.token');
				let expiration = parseInt(token.replace(/\..*$/, '') + '000');

				// check if the token has not expired
				if( (new Date()) >= new Date(expiration) ) {
					token = null;
				}
			}

			return token;
		}, 


		/**
		 * Returns a dictionary of tokes used in the strategies.
		 * Tokes are regenrated everytime this getter is called to make sure
		 * the tokens use all available data.
		 *  
		 * @param {*} state 
		 */
		tokens(state) {
            state.current.tokens = {};

            let opportunity = state.current?.opportunity || null;
            let company = state.current?.company || null;

            if(opportunity) {
                Object.keys(opportunity.content).forEach((form) => {
                    let fields = opportunity.content[form].fields;
                    for (let field in fields) {
                        state.current.tokens[form +'.'+ field] = fields[field];
                    }
                });
            }

            if(company) {
                Object.keys(company.content).forEach((form) => {
                    if(form == 'company') {
                        let fields = company.content[form].fields;
                        for (let field in fields) {
                            state.current.tokens[form +'.'+ field] = fields[field];
                        }
                    }
                });
            }

            return state.current.tokens;
		},


		partner(state) {
			return state.partner;
		}

	},

	modules: {
	}
})
