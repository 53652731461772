<template>
    <section class="block pb-5 d-flex pt-5 --align-items-center h-100 bg-gradient-soft welcome">
        <div class="container">

            <div class="row">
                <div class="col-lg-8 m-auto">
                    <div class="appc-widget">
                        <div class="header-text text-center pb-3 pt-3">
                            <div class="fs-2 fw-bold" v-html="$root.partner?.labels?.['home.headline']"></div>
                            <div class="lead pb-2" v-html="$root.partner?.labels?.['home.subheading']"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 d-flex flex-row justify-content-center pt-4">
                    <div class="card shadow-sm mb-4 mx-3" @click="goto('my-opportunities')">
                        <div class="card-body rounded-5 p-4 pb-5 d-flex flex-column">
                            <img src="/resources/images/icons/dollar.svg" class="rounded-circle mb-3" alt="">
                            <h5 class="card-title text-black">
                                <div class="d-block-inline" v-html="$root.partner?.labels?.['home.offerCardTitle']"></div>
                            </h5>
                            <div class="card-text fs-6 flex-fill" v-html="$root.partner?.labels?.['home.offerCardDescription']"></div>
                        </div>
                    </div>
                    <div class="card shadow-sm mb-4 mx-3" @click="goto('strategies')">
                        <div class="card-body rounded-5 p-4 pb-5 d-flex flex-column">
                            <img src="/resources/images/icons/target-marketing.svg" class="rounded-circle mb-3" alt="">
                            <h5 class="card-title text-black">
                                <div class="d-block-inline" v-html="$root.partner?.labels?.['home.skillsCardTitle']"></div>
                            </h5>
                            <div class="card-text fs-6 flex-fill" v-html="$root.partner?.labels?.['home.skillsCardDescription']"></div>
                        </div>
                    </div>
                    <!--
                    <div class="card shadow-sm mb-4 mx-3" @click="goto('library')">
                        <div class="card-body rounded-5 p-4 d-flex flex-column">
                            <img src="/resources/images/icons/library.svg" class="rounded-circle mb-3" alt="">
                            <h5 class="card-title text-black">Library</h5>
                            <p class="card-text fs-6 flex-fill">Need inspiration? Use our library of business opportunities to 
                                create your own. Save time and learn from proven business models.
                            </p>
                            <!- - <p class="text-end py-0 mb-0">
                                <span class="material-icons-outlined icon-arrow">
                                arrow_forward
                                </span>
                            </p> - ->
                        </div>
                    </div>
                    -->
                </div>

            </div>

            <!-- <div class="row">
                <div class="col-10 offset-1 text-center py-4">

                   
                    <router-link ref="btn-opportunities"  :to="$root.links.MY_OPPORTUNITIES" class="btn btn-outline-primary btn-opportunities font-weight-light mb-3 mx-2 px-4 btn-pill">My Opportunities</router-link>
                    <router-link ref="btn-apps"  :to="$root.links.LIBRARY_STRATEGIES_AI" class="btn btn-primary btn-apps font-weight-light mb-3 mx-2 px-5 btn-pill">Strategies</router-link>
                    <a href="#" @click.prevent="watchVideo()" class="btn btn-primary fw-normal mx-2 mb-3 btn-pill">
                        <span class="material-icons-outlined pull-left me-2">
                        play_circle
                        </span>

                        Watch the Video</a>
                </div>
            </div> -->


            <div class="row">
                <div class="col-12">
                    <div class="appc-widget pt-5 text-center">
                        <ul class="d-flex flex-row jus justify-content-evenly list-inline list-unstyled mx-auto w-50">
                            <li v-for="(item, index) in links" :key="index"
                                @click="shortcut(item)"
                                class="list-inline-item mx-2"
                                :class="[item.admin === true && $store.getters.user?.is_admin == false? 'd-none' : '']"
                                style="width: 100px"
                                >
                                <IconButton class="" 
                                    style="zoom: 1.2"
                                    :icon="item.icon" 
                                    :title="item.label"></IconButton>
                                <!-- <router-link :to="item.link">
                                </router-link> -->
                                <small class="d-block p-2 cursor-pointer">{{ item.label }}</small>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="background"></div>
    </section>
</template>

<script>

import IconButton from "@/components/IconButton.vue";

export default {
    components:{
        IconButton
    },
    
    data() {
        return {
            logo: true,
            explainerURL: 'https://www.youtube.com/embed/5r50Y42BUkM',
        }
    }, 

    computed: {
        links() {
            return [
                {
                    label: 'My Products',
                    name: 'opportunity',
                    icon: 'attach_money',
                    admin: false,
                    link: this.$root.links.MY_OPPORTUNITIES,
                },
                {
                    label: 'AI',
                    name: 'ai',
                    icon: 'psychology',
                    admin: false,
                    link: this.$root.links.LIBRARY_STRATEGIES_AI,
                },
                {
                    label: 'Ads',
                    name: 'ads',
                    icon: 'ads_click',
                    admin: false,
                    link: this.$root.links.LIBRARY_STRATEGIES_ADS,
                },
                {
                    label: 'Emails',
                    name: 'emails',
                    icon: 'forward_to_inbox',
                    admin: false,
                    link: this.$root.links.LIBRARY_STRATEGIES_EMAILS,
                },
                {
                    label: 'Settings',
                    name: 'settings',
                    icon: 'settings',
                    admin: false,
                    link: this.$root.links.SETTINGS_PROFILE,
                },
                // {
                //     label: 'Pages',
                //     name: 'pages',
                //     icon: 'request_page',
                //     admin: false,
                //     link: this.$root.links.LIBRARY_STRATEGIES_PAGES,
                // },
                // {
                //     label: 'Tutorial',
                //     name: 'tutorial',
                //     icon: 'school',
                //     admin: false,
                //     link: 'https://docs.google.com/presentation/d/1ByPNd37xH5WBGVSgmqI60Nmjz8yQvbBUGLUM__fK2L0/edit?usp=sharing',
                // },
                // {
                //     label: 'Apps',
                //     icon: 'list_alt',
                //     admin: true,
                //     link: this.$root.links.APPS,
                // },
                // {
                //     label: 'Workbooks',
                //     icon: 'list_alt',
                //     admin: true,
                //     link: this.$root.links.WORKBOOKS,
                // },
            ]
        }
    },

    methods: {
        watchVideo() {
            // this.$root.watchVideo(this.explainerURL);
        },
        goto(target) {
            let path;
            switch (target) {
                case 'my-opportunities':
                    path = this.$root.links.MY_OPPORTUNITIES;
                    break;
            
                case 'strategies':
                    path = this.$root.links.LIBRARY_STRATEGIES_AI;
                    break;
            
                case 'library':
                    path = this.$root.links.LIBRARY_OPPORTUNITIES;
                    break;
            
                case 'settings':
                    path = this.$root.links.SETTINGS_PROFILE;
                    break;
                default:
                    break;
            }

            this.$router.push( {path: path} );
        }, 



        quickCreateStrategy(options) {

            let modal = this.$root.createStrategyIntent(options);

            // let scope = this;
            // let modal = this.$root.prepareStrategyData(
            //     {
            //         title: 'Create strategy',
            //         buttons: {
            //             okay: {
            //                 callback: (component) => {
            //                     /**
            //                      * component.data
            //                      * component.data.title
            //                      * component.data.excerpt
            //                      * component.data.opportunity_id
            //                      * component.data.opportunity_name
            //                      * component.data.opportunity_title
            //                      * 
            //                      */
            //                     let context = {...component.data, ...{
            //                             /**
            //                              * Overwrite title to add suffix 
            //                              * 
            //                              * TODO: strategy types must be a category or a tag not a "title suffix"
            //                              */
            //                             title: (options?.suffix? options.suffix + ': ' + component.data.title : component.data.title)
            //                         }
            //                     };

            //                     setTimeout(()=>{
            //                         /**
            //                          * @param {Object} selction
            //                          * @param {Object} selction.text
            //                          * 
            //                          * @param {Object} usecase
            //                          * @param {Object} usecase.name
            //                          * @param {Object} usecase.slug
            //                          * @param {Object} usecase.description
            //                          */
            //                         scope.$root.AIChooseStrategy( async function(selection, usecase) {
            //                             scope, selection, usecase;

            //                             let request = {
            //                                 post_title: context.title,
            //                                 post_excerpt: context.excerpt,
            //                                 post_content: selection.text,
            //                                 meta_input: {
            //                                     opportunity_id: context.opportunity_id,
            //                                     opportunity_name: context.opportunity_name,
            //                                     opportunity_title: context.opportunity_title
            //                                 }
            //                             };

            //                             scope.$store.commit('SET_LOADING_STATE', true);
            //                             let response = await scope.$store.getters.wp.createAIStrategyForOpportunity(request);
            //                             await scope.$store.dispatch('reloadStrategies');
            //                             scope.$store.commit('SET_LOADING_STATE', false);

            //                             if(response?.ID) {
            //                                 scope.$root.openStrategy(response /** strategy data */);
            //                             }
            //                         });
            //                     }, 500);
            //                 }
            //             }
            //         }
            //     }
            // );

            modal;
        },

        shortcut(item) {
            let scope = this, 
                target = item.name,
                link = item.link;
            scope;

            switch (target) {
                // case 'ai':
                //     this.quickCreateStrategy(/* options */ {
                //         suffix: 'ai'
                //     });
                //     break;
                    
                // case 'opportunity':
                //     this.$root.createOpportunityIntent();
                //     break;
                    
                case 'tutorial':
                    window.open(link, 'CoreOffer Tutorial');
                    break;
                    
                default:
                    this.$router.push({
                        path: link
                    });
                    break;

            }
            link, target;
        }
    }, 

    mounted() {
        // setTimeout(()=>{
        //     document.querySelector('.btn-apps').focus();
        // }, 500);
    },
}
</script>


<style scoped>

.cursor-pointer {
    cursor: pointer;
}
.btn-apps:focus {
    box-shadow: 0 0 0 0.25rem #404fff38 !important;
}

.bg-gradient-soft {
    background-color: #404fff00;
    background-image: linear-gradient(0deg,var(--bg-canvas) 33%,rgb(13 110 253/4%));
}


.welcome .card {
    width: 320px;
    margin-right: 1%;
    background-color: #f9fafbcc;
    transition: all 0.15s;
}

.welcome .card:hover {
    background-color: #fcfcfd;
    border-color: #aaa;
    cursor: pointer;
    box-shadow: 0 4px 8px #404fff2b !important;
}
.welcome .card.active {
    pointer-events: none;
    background-color: #404fff;
    border-color: #404fff;
    color: #fff;
}

.welcome .card img {
    width: 64px;
    padding: 1px;
}

.welcome .card .icon-arrow {
    opacity: 0;
    transition: all 0.2s;
    transform: translateX(-10px);
    float: right;
}

.welcome .card:hover .icon-arrow {
    opacity: 1;
    transform: translateX(0px);
}
</style>